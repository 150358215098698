<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep1"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep2"
            v-on:formFunction="formFunction"
          />
        </div>
        <!-- Infobesked -->
        <message
          v-show="info.step2"
          :message="info.step2"
          type="info"
          v-on:formFunction="formFunction"
        />
        <!-- Fejlbesked -->
        <message
          v-show="error.step2"
          :message="error.step2"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 3">
      <form id="step3" v-on:submit.prevent="submitStep3">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 3 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 3"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Pakkekomponent -->
        <packages
          altLinkText="Læs mere om vores indbo- og årsrejseforsikring"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <!-- Extra packages -->
        <coverages
          v-model="product.coverages"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <div class="container small">
          <!-- Infobesked -->
          <message
            v-show="info.step3"
            :message="info.step3"
            type="info"
            v-on:formFunction="formFunction"
          />
        </div>
      </form>
    </template>

    <!-- Buttons on bottom -->
    <div class="container small">
      <ProgressButtons
        :step="product.step"
        :steps="product.steps"
        :existingPolicy="existingPolicy"
        @changeStep="changeStep"
      />
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :productId="product.productId"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
    >
      Med vores sumløse indboforsikring er familiens ejendele dækket i tilfælde
      af fx brand og tyveri. Vi dækker også det ansvar, du har som privat
      person, ligesom du kan få hjælp ved identitetstyveri og online chikane.
    </product-footer>
  </div>
</template>
<script>
import P08 from "@/assets/json/P08.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import FormStep from "@/components/FormStep.vue";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";
import debounce from "lodash/debounce";
import coverages from "@/components/coverages.vue";

export default {
  name: "Veteranbilforsikring",
  components: {
    FormInput,
    progressIndicator,
    ProgressButtons,
    message,
    productFooter,
    packages,
    FormStep,
    coverages,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  mixins: [commonProduct, validateData],
  data() {
    return {
      productId: "P08",
      product: this.copy(P08),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      // Push on without input
      this.$router
        .push({ name: "Indbo- og årsrejseforsikring", query: query })
        .catch(() => {});
    }
  },
  computed: {
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (!value.value || value.error) {
            this.cerror(value);
            return false;
          }
        }
      }
      return true;
    },
  },
  watch: {
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
  },
  methods: {
    /*
     * Reset zipcode
     */
    resetZipcode() {
      this.product.fields.zipCode.value = null;
    },
    /*
     * Set zipcode
     */
    setZipcode(value) {
      this.product.fields.zipCode.value = value.postnummer.nr;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Show info on step 2 for specific age range
      this.info.step2 =
        this.product.fields.age.value >= 18 &&
        this.product.fields.age.value <= 29
          ? {
              headline: "Er du mellem 18-29 år?",
              text: "Bor du sammen med en roomie, på kollegieværelse eller i et mindre bofællesskab med 2 venner, så kan Aros Ung Indbo være en fordel for dig. Kontakt os for at høre nærmere.",
              button: {
                text: "Kontakt mig",
                function: "messageBox",
              },
            }
          : null;

      // Show info about insurance of valuable items
      this.info.step3 = {
        headline: "Har du værdifulde genstande i dit indbo?",
        text: "Med vores sumløse indboforsikring er der ikke et øvre maksimum på værdien af det samlede indbo, du har i hjemmet. Prisen er beregnet med den forudsætning, at guld/sølv er dækket op til 100.000 kr., udhus/garage op til 50.000 kr., samt at du ikke har enkeltgenstande (fx et smykke, designermøbel eller lignende) med en værdi over 40.000 kr.<br><br>Det er dog muligt at hæve forsikringssummen på guld/sølv, udhus/garage og/eller tilkøbe forsikring af enkeltgenstande med en værdi over 40.000 kr. samt cykelkasko.<br><br>Udfyld kontaktformularen, og vores kundeservice kontakter dig for at give dig et tilbud på dine forsikringer.",
        button: {
          text: "Kontakt mig",
          function: "messageBox",
        },
      };

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) {
        this.cerror("Not all requirements met");
        return;
      }

      // Remove error
      self.error.price = null;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then((response) => {
          // If weird error shows, run function again
          if (response.data.objects && response.data.objects.length < 3) {
            self.calculateEveryPrice();
            return;
          }

          self.insertPrice(response.data);
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[0].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Roof type not set
      if (this.product.fields.roofType.value === null) {
        this.product.fields.roofType.error = this.product.fields.roofType.validationMessage;

        // Set loading to false
        self.product.steps[0].loading = false;

        return;
      }

      // Update info on roof type
      if (this.product.fields.roofType.value) {
        this.error["step1"] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Da din bolig har stråtag, kan vi desværre ikke beregne en pris online. Udfyld kontaktformularen, og vores kundeservice kontakter dig for at give dig et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        // Set loading to false
        self.product.steps[0].loading = false;

        return;
      }

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.product.steps[0].loading = false;
    },
    /*
     * Submit step 2 in form
     */
    async submitStep2() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[1].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set step 1 to loading
      self.product.steps[1].loading = false;
    },
    /*
     * Submit step 3 in form
     */
    submitStep3() {
      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
